<template>
  <Layout>
    <BHeader>
      <template v-slot:right>
        <el-date-picker
          v-model="searchDate"
          type="daterange"
          format="yyyy-MM-dd"
          unlink-panels
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          @change="handleSearch"
        />
      </template>
    </BHeader>
    <div class="main-wrapper">
      <div class="columns">
        <div class="items">
          <div class="title">
            连接资源报错
          </div>
          <el-table
            v-loading="linkErrorInfo.loading"
            :data="linkErrorInfo.list"
            style="width: 100%"
          >
            <el-table-column
              prop="errMsg"
              label="错误信息"
            />
            <el-table-column
              prop="errCount"
              label="报错次数"
            />
            <el-table-column
              prop="errRate"
              label="报错率"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errRate) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="affectedUser"
              label="报错用户数"
            />
            <el-table-column
              prop="affectPercent"
              label="影响占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.affectPercent) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="errPercent"
              label="占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errPercent) }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="items">
          <div class="title">
            登录报错
          </div>
          <el-table
            v-loading="loginInfo.loading"
            :data="loginInfo.list"
            style="width: 100%"
          >
            <el-table-column
              prop="errMsg"
              label="错误信息"
            />
            <el-table-column
              prop="errCount"
              label="报错次数"
            />
            <el-table-column
              prop="errRate"
              label="报错率"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errRate) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="affectedUser"
              label="报错用户数"
            />
            <el-table-column
              prop="affectPercent"
              label="影响占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.affectPercent) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="errPercent"
              label="占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errPercent) }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="items">
          <div class="title">
            接口报错
          </div>
          <el-table
            v-loading="interfaceInfo.loading"
            :data="interfaceInfo.list"
            style="width: 100%"
          >
            <el-table-column
              prop="errMsg"
              label="错误信息"
            />
            <el-table-column
              prop="errCount"
              label="报错次数"
            />
            <el-table-column
              prop="errRate"
              label="报错率"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errRate) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="affectedUser"
              label="报错用户数"
            />
            <el-table-column
              prop="affectPercent"
              label="影响占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.affectPercent) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="errPercent"
              label="占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errPercent) }}%
              </template>
            </el-table-column>
          </el-table>
          <div class="more">
            <el-button
              type="text"
              @click="searchInterfaceError"
            >
              加载更多
            </el-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="items">
          <div class="title">
            充值报错
          </div>
          <el-table
            v-loading="rechargeInfo.loading"
            :data="rechargeInfo.list"
            style="width: 100%"
          >
            <el-table-column
              prop="errMsg"
              label="错误信息"
            />
            <el-table-column
              prop="errCount"
              label="报错次数"
            />
            <el-table-column
              prop="errRate"
              label="报错率"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errRate) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="affectedUser"
              label="报错用户数"
            />
            <el-table-column
              prop="affectPercent"
              label="影响占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.affectPercent) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="errPercent"
              label="占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errPercent) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
            />
          </el-table>
        </div>
        <div class="items">
          <div class="title">
            流桌面报错
          </div>
          <el-table
            v-loading="steamDeskInfo.loading"
            :data="steamDeskInfo.list"
            style="width: 100%"
          >
            <el-table-column
              prop="errCode"
              label="错误代码"
            />
            <el-table-column
              prop="errMsg"
              label="错误消息"
            />
            <el-table-column
              prop="errRate"
              label="报错率"
            >
              <template v-slot="{row}">
                {{ fixed2(row.errRate) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="affectedUser"
              label="报错用户数"
            />
            <el-table-column
              prop="affectPercent"
              label="影响占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.affectPercent) }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="percent"
              label="占比"
            >
              <template v-slot="{row}">
                {{ fixed2(row.percent) }}%
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  connectResourceErr,
  topUpErr,
  loginErr,
  streamDeskErr,
  interfaceErr
} from '@/api/log'
const dateNow = () => {
  const start = new Date(new Date(new Date().toLocaleDateString()).getTime())
  const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000)
  return [start, end]
}

export default {
  name: 'ErrorStatistics',
  data () {
    return {
      searchDate: [],
      linkErrorInfo: {
        loading: false,
        list: []
      },
      rechargeInfo: {
        loading: false,
        list: []
      },
      loginInfo: {
        loading: false,
        list: []
      },
      steamDeskInfo: {
        loading: false,
        list: [],
        pageSize: 1
      },
      interfaceInfo: {
        loading: false,
        list: []
      }
    }
  },
  created () {
    const [start, end] = dateNow()
    start.setTime(start.getTime() - 3600 * 1000 * 24)
    end.setTime(end.getTime() - 3600 * 1000 * 24)
    this.searchDate = [start.getTime(), end.getTime()]
    this.handleSearch()
  },
  methods: {
    handleSearch () {
      this.steamDeskInfo.pageSize = 1
      this.searchLinkError()
      this.searchRechargeError()
      this.searchLoginError()
      this.searchSteamDeskError()
      this.searchInterfaceError()
    },
    searchLinkError () {
      const [start, end] = this.searchDate
      const startTime = Math.floor(start / 1000)
      const endTime = Math.floor(end / 1000)
      this.linkErrorInfo.loading = true
      connectResourceErr({ startTime, endTime })
        .then(res => {
          this.linkErrorInfo.list = res.data.list || []
        })
        .finally(() => {
          this.linkErrorInfo.loading = false
        })
    },
    searchRechargeError () {
      const [start, end] = this.searchDate
      const startTime = Math.floor(start / 1000)
      const endTime = Math.floor(end / 1000)
      this.rechargeInfo.loading = true
      topUpErr({ startTime, endTime })
        .then(res => {
          this.rechargeInfo.list = res.data.list || []
        })
        .finally(() => {
          this.rechargeInfo.loading = false
        })
    },
    searchLoginError () {
      const [start, end] = this.searchDate
      const startTime = Math.floor(start / 1000)
      const endTime = Math.floor(end / 1000)
      this.loginInfo.loading = true
      loginErr({ startTime, endTime })
        .then(res => {
          this.loginInfo.list = res.data.list || []
        })
        .finally(() => {
          this.loginInfo.loading = false
        })
    },
    searchSteamDeskError () {
      const [start, end] = this.searchDate
      const startTime = Math.floor(start / 1000)
      const endTime = Math.floor(end / 1000)
      this.steamDeskInfo.loading = true
      streamDeskErr({ startTime, endTime })
        .then(res => {
          this.steamDeskInfo.list = res.data.list || []
        })
        .finally(() => {
          this.steamDeskInfo.loading = false
        })
    },
    searchInterfaceError () {
      const [start, end] = this.searchDate
      const startTime = Math.floor(start / 1000)
      const endTime = Math.floor(end / 1000)
      this.interfaceInfo.loading = true
      interfaceErr({ startTime, endTime })
        .then(res => {
          this.interfaceInfo.list = res.data.list || []
        })
        .finally(() => {
          this.interfaceInfo.loading = false
        })
    },
    fixed2 (value) {
      return ((value || 0) / 100).toFixed(2)
    }
  }
}
</script>

<style scoped lang="less">
  @gap: 20px;
  .main-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > .columns{
      width: calc(~'50% -' @gap / 2);
      .items{
        margin-bottom: @gap;
        border:  1px solid transparent;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        /*height:100%;*/
        /*overflow: auto;*/
        *.more{
          text-align: center;
        }
        & > .title{
          height: 30px;
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 20px;
          margin: 20px 0 0;
          &:before{
            content: '';
            display: block;
            width: 4px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: @primary;
          }
        }
      }
    }
  }
</style>
